import _ from 'lodash';

class Backend {
  constructor(apis) {
    this.apis = apis;
  }

  getEvent({ eventId, token, apiName, subdomain }) {
    return this.apis[apiName].getEvent({ eventId, token, subdomain });
  }

  getEventAnalytics({ analyticsToken, eventId, apiName }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].getEventAnalytics({ analyticsToken, eventId });
  }

  getSurveyResults({ eventId, token, apiName, subdomain }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].getSurveyResults({ eventId, token, subdomain });
  }

  getMediasWithFilters({ eventId, eventMedias, apiName, subdomain, filters }) {
    /**
     * @todo Support more than one capture mode at a time. Remove this code when that is done.
     */
    if (filters?.captureMode instanceof Set) {
      filters.captureMode = [...filters.captureMode][0];
    }
    return this.apis[apiName].getMediasWithFilters({ eventId, eventMedias, subdomain, filters });
  }

  getMediaIndex({ eventId, subdomain, filters, dateAfter, apiName }) {
    return this.apis[apiName].getMediaIndex({ eventId, subdomain, filters, dateAfter });
  }

  getMedias({ eventId, eventMedias, apiName, subdomain }) {
    return this.apis[apiName].getMedias({ eventId, eventMedias, subdomain });
  }

  getMediasByIds({ eventId, mediaIds, apiName, subdomain }) {
    return this.apis[apiName].getMediasByIds({ eventId, mediaIds, subdomain });
  }

  getMedia({ eventId, mediaId, apiName, subdomain }) {
    return this.apis[apiName].getMedia({ eventId, mediaId, subdomain });
  }

  getTemplate({ eventId, mediaId, apiName }) {
    return this.apis[apiName].getTemplate({ eventId, mediaId });
  }

  sharePhoto({ eventId, mediaId, email, apiName }) {
    return this.apis[apiName].sharePhoto({ eventId, mediaId, email });
  }

  requestPlans({ apiName, env }) {
    return this.apis[apiName].getPlans(env);
  }

  requestBilling({ organizationId, apiName }) {
    return this.apis[apiName].getBilling({ organizationId });
  }

  requestDevices({ accessToken, apiName }) {
    return this.apis[apiName].getDevices({ accessToken });
  }

  async requestProfile(options) {
    const operator = (await (await this.apis.salsa.getAppUserOperator(options)).json()).data;
    const organization = operator.organization;
    const operatorFields = [
      'company',
      'academyProgress',
      'portfolio',
      'newEmail',
      'onboarded',
      'shopifyCustomerEmail',
      'dropbox',
    ];
    return _.merge({}, organization, _.pick(operator, operatorFields));
  }

  requestInvoices({ organizationId, apiName }) {
    return this.apis[apiName].getInvoices({ organizationId });
  }

  removeDevice({ accessToken, deviceId, apiName }) {
    return this.apis[apiName].removeDevice({ accessToken, deviceId });
  }

  updateOrganization({ organizationId, requestBody, apiName, accessToken }) {
    return this.apis[apiName].updateOrganization({ organizationId, requestBody, accessToken });
  }

  transformEvent({ eventBody, apiName }) {
    return this.apis[apiName].transformEvent({ eventBody });
  }

  transformMedia({ mediaBody, apiName }) {
    return this.apis[apiName].transformMedia({ mediaBody });
  }

  transformMedias({ mediasBody, apiName }) {
    return this.apis[apiName].transformMedias({ mediasBody });
  }

  transformAnalytics({ analytics, analyticsToken, surveyRows, apiName }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].transformAnalytics({ analytics, analyticsToken, surveyRows });
  }

  transformProposalData(proposalData, isSalsa, forceBranding) {
    const topLevelBrandingSettings = proposalData.brandingSettings;

    // Chose betwen live gallery and branding settings to provide
    // virtual booth related information.
    const brandingSubtitle = topLevelBrandingSettings.subtitle.enabled ? topLevelBrandingSettings.subtitle.value : '';
    const liveGallerySubtitle = proposalData.liveGallery.subtitleEnabled ? proposalData.subtitle : '';
    const virtualBoothSubtitle =
      forceBranding || proposalData.liveGallery.useBrandingSettings ? brandingSubtitle : liveGallerySubtitle;
    const virtualBooth = {
      virtualBooth: {
        subtitle: virtualBoothSubtitle,
      },
    };

    // If this is a Salsa proposal then add the virtual booth information
    // to make sure we don't break Salsa proposals.
    if (isSalsa) {
      return {
        ...proposalData,
        ...virtualBooth,
      };
    }

    // Chose between top-level and proposal-level branding settings.
    // Note: Albeit we always set the proposal-level branding settings,
    // let's coalesce for the top-level one just to be safe.
    const proposalConfig = proposalData.proposalConfig;
    const proposalLevelBrandingSettings = proposalConfig.brandingSettings;
    const brandingSettings =
      forceBranding || proposalConfig.useBrandingSettings
        ? topLevelBrandingSettings
        : proposalLevelBrandingSettings ?? topLevelBrandingSettings;

    let emailTemplate = {
      ...proposalData.emailTemplate,
      logoSectionEnabled: true,
      logoSection: {
        ...proposalData.emailTemplate.logoSection,
        tagline: proposalData.title,
        subtitle: proposalData.emailTemplate.subtitleEnabled ? proposalData.emailTemplate.subtitle : '',
        logoImage: proposalData.emailTemplate.logoSectionEnabled
          ? proposalData.emailTemplate.logoSection.logoImage
          : '',
        logoUrl: proposalData.emailTemplate.logoSectionEnabled ? proposalData.emailTemplate.logoSection.logoURL : '',
      },
    };
    if (emailTemplate.useBrandingSettings && proposalData.brandingSettings) {
      emailTemplate = {
        ...emailTemplate,
        accentColor: proposalData.brandingSettings.accentColor,
        backgroundColor: proposalData.brandingSettings.backgroundColor,
        subtitleEnabled: proposalData.brandingSettings.subtitle.enabled,
        subtitle: proposalData.brandingSettings.subtitle.value,
        logoSectionEnabled: proposalData.brandingSettings.logo.enabled,
        logoSection: {
          ...emailTemplate.logoSection,
          subtitle: proposalData.brandingSettings.subtitle.enabled ? proposalData.brandingSettings.subtitle.value : '',
          logoImage: proposalData.brandingSettings.logo.enabled ? proposalData.brandingSettings.logo.imageUrl : '',
          logoUrl: proposalData.brandingSettings.logo.enabled ? proposalData.brandingSettings.logo.linkUrl : '',
        },
        sharingSectionEnabled: proposalData.brandingSettings.social.enabled,
        sharingSection: {
          socialSharing: {
            facebook: {
              enabled: proposalData.brandingSettings.social.facebookEnabled,
            },
            twitter: {
              enabled: proposalData.brandingSettings.social.twitterEnabled,
            },
            reddit: {
              enabled: proposalData.brandingSettings.social.redditEnabled,
            },
            tumblr: {
              enabled: proposalData.brandingSettings.social.tumblrEnabled,
            },
            pinterest: {
              enabled: proposalData.brandingSettings.social.pinterestEnabled,
            },
          },
        },
        customSectionEnabled: emailTemplate.customSectionEnabled || proposalData.brandingSettings.ctaSection.enabled,
        customSection: {
          ...emailTemplate.customSection,
          callToActionButtonTitle: proposalData.brandingSettings.ctaSection.enabled
            ? proposalData.brandingSettings.ctaSection.buttonLabel
            : '',
          url: proposalData.brandingSettings.ctaSection.enabled
            ? proposalData.brandingSettings.ctaSection.buttonUrl
            : '',
        },
        footerSectionEnabled: proposalData.brandingSettings.company.enabled,
        footerSection: {
          headline: proposalData.brandingSettings.company.headline,
          details: {
            email: proposalData.brandingSettings.company.email,
            phone: proposalData.brandingSettings.company.phone,
            website: proposalData.brandingSettings.company.website,
          },
          socialAccounts: {
            facebook: proposalData.brandingSettings.company.facebook,
            twitter: proposalData.brandingSettings.company.twitter,
            instagram: proposalData.brandingSettings.company.instagram,
          },
        },
      };
    }

    return {
      ...proposalData,
      micrositeAccentColor: brandingSettings.accentColor,
      backgroundUrl: brandingSettings.featuredImage.enabled ? brandingSettings.featuredImage.imageUrl : '',
      logoLink: brandingSettings.logo.linkUrl,
      logoUrl: brandingSettings.logo.imageUrl,
      subtitle: brandingSettings.subtitle.enabled ? brandingSettings.subtitle.value : '',
      liveGallery: {
        ...proposalData.liveGallery,
        theme: brandingSettings.featuredImage.theme,
        leadSection: brandingSettings.logo.enabled,
        company: {
          headline: brandingSettings.company.headline,
          email: brandingSettings.company.email,
          phone: brandingSettings.company.phone,
          website: brandingSettings.company.website,
          facebook: brandingSettings.company.facebook,
          twitter: brandingSettings.company.twitter,
          instagram: brandingSettings.company.instagram,
        },
        termsLink: brandingSettings.company.termsLink,
        privacyLink: brandingSettings.company.privacyLink,
        companySection: brandingSettings.company.enabled,
      },
      proposalConfig: {
        ...proposalConfig,
        button: {
          enabled: brandingSettings.ctaSection.enabled,
          text: brandingSettings.ctaSection.buttonLabel,
          url: brandingSettings.ctaSection.buttonUrl,
        },
      },
      emailTemplate,
      ...virtualBooth,
    };
  }

  signUp({ user, apiName }) {
    return this.apis[apiName].signUp({ user });
  }

  logIn({ user, apiName }) {
    return this.apis[apiName].logIn({ user });
  }

  resetPasswordInitiate({ email, apiName }) {
    return this.apis[apiName].resetPasswordInitiate({ email });
  }

  resetPasswordValidate({ token, apiName }) {
    return this.apis[apiName].resetPasswordValidate({ token });
  }

  resetPasswordFinally({ token, newPassword, apiName }) {
    return this.apis[apiName].resetPasswordFinally({ token, newPassword });
  }

  requestPricing(options) {
    return this.apis.salsa.getAppUserPricing(options);
  }

  passwordToken({ id, password, apiName, idType }) {
    return this.apis[apiName].passwordToken({ id, password, idType });
  }

  incrementShareClicks({ body, apiName }) {
    return this.apis[apiName].incrementShareClicks({ body });
  }

  transformPasswordToken({ tokenBody, apiName }) {
    return this.apis[apiName].transformPasswordToken({ tokenBody });
  }

  getAppEvent({ accessToken, eventId }) {
    return this.apis.salsa.getAppEvent({ accessToken, eventId });
  }

  downloadZipFile({ eventId }) {
    return this.apis.fiesta.downloadZipFile({ eventId });
  }

  createEvent({ accessToken, data }) {
    return this.apis.salsa.createEvent({ accessToken, data });
  }

  updateEvent({ accessToken, data, id }) {
    return this.apis.salsa.updateEvent({ accessToken, data, id });
  }

  duplicateEvent({ accessToken, id }) {
    return this.apis.salsa.duplicateEvent({ accessToken, id });
  }

  deleteEvents({ accessToken, data }) {
    return this.apis.salsa.deleteEvents({ accessToken, data });
  }

  listEvents({ accessToken, level, data }) {
    return this.apis.salsa.listEvents({ accessToken, level, data });
  }

  listMedias({ accessToken, eventId, data }) {
    return this.apis.salsa.listMedias({ accessToken, eventId, data });
  }

  deleteMedias({ accessToken, eventId, data }) {
    return this.apis.salsa.deleteMedias({ accessToken, eventId, data });
  }

  uploadAsset(options) {
    return this.apis.salsa.uploadAsset(options);
  }

  listAssets({ accessToken, data }) {
    return this.apis.salsa.listAssets({ accessToken, data });
  }

  loadSysAssets({ category }) {
    return this.apis.salsa.loadSysAssets({ category });
  }

  deleteAssets({ accessToken, data }) {
    return this.apis.salsa.deleteAssets({ accessToken, data });
  }

  emailLinks(options) {
    return this.apis.salsa.emailLinks(options);
  }

  emailCancellation(options) {
    return this.apis.salsa.emailCancellation(options);
  }

  addSendingDomain(options) {
    return this.apis.salsa.addSendingDomain(options);
  }

  deleteSendingDomain(options) {
    return this.apis.salsa.deleteSendingDomain(options);
  }

  updateOperator(options) {
    return this.apis.salsa.updateOperator(options);
  }

  updateAcademyVideoProgress(options) {
    return this.apis.salsa.updateAcademyVideoProgress(options);
  }

  getPrintTemplate(options) {
    return this.apis.salsa.getPrintTemplate(options);
  }

  createPrintTemplate(options) {
    return this.apis.salsa.createPrintTemplate(options);
  }

  listPrintTemplates(options) {
    return this.apis.salsa.listPrintTemplates(options);
  }

  deletePrintTemplates(options) {
    return this.apis.salsa.deletePrintTemplates(options);
  }

  listSysTemplates(options) {
    return this.apis.salsa.listSysTemplates(options);
  }

  downloadSysTemplate(options) {
    return this.apis.salsa.downloadSysTemplate(options);
  }

  listSysFonts(options) {
    return this.apis.salsa.listSysFonts(options);
  }

  listPrintTemplateLayouts(options) {
    return this.apis.salsa.listPrintTemplateLayouts(options);
  }

  listPoseTips(options) {
    return this.apis.salsa.listPoseTips(options);
  }

  updatePrintTemplate(options) {
    return this.apis.salsa.updatePrintTemplate(options);
  }

  getAppUserStats(options) {
    return this.apis.salsa.getAppUserStats(options);
  }

  listPortfolioEvents(options) {
    const api = options.isSalsa ? this.apis.salsa : this.apis.fiesta;
    return api.listPortfolioEvents(options);
  }

  getPortfolioSettings(options) {
    const api = options.isSalsa ? this.apis.salsa : this.apis.fiesta;
    return api.getPortfolioSettings(options);
  }

  updateUserPlan(options) {
    return this.apis.salsa.updateUserPlan(options);
  }

  pauseUserPlan(options) {
    return this.apis.salsa.pauseUserPlan(options);
  }

  subscribe(options) {
    return this.apis.salsa.subscribe(options);
  }

  cancelPlanSchedule(options) {
    return this.apis.salsa.cancelPlanSchedule(options);
  }

  verifyEmail(options) {
    return this.apis.salsa.verifyEmail(options);
  }

  resendVerification(email) {
    return this.apis.salsa.resendVerification(email);
  }

  uploadMedia(options) {
    return this.apis.salsa.uploadMedia(options);
  }

  processMedia(options) {
    return this.apis.salsa.processMedia(options);
  }

  getVirtualBooth(options) {
    return this.apis.salsa.getVirtualBooth(options);
  }

  updateEnvironments({ env }) {
    this.apis.forEach((api) => {
      api.updateEnvironment({ env });
    });
  }

  checkForMedia(options) {
    return this.apis.salsa.checkForMedia(options);
  }

  submitPendingShare(options) {
    return this.apis.salsa.submitPendingShare(options);
  }

  getS3FormData(options) {
    return this.apis.salsa.getS3FormData(options);
  }

  saveCapture(options) {
    return this.apis.salsa.saveCapture(options);
  }

  getShopifyCustomerEmail(options) {
    return this.apis.salsa.getShopifyCustomerEmail(options);
  }

  getMarketingMaterials(options) {
    return this.apis.salsa.getMarketingMaterials(options);
  }

  downloadMarketingMaterial(options) {
    return this.apis.salsa.downloadMarketingMaterial(options);
  }

  getProposalData(options) {
    const api = options.isSalsa ? this.apis.salsa : this.apis.fiesta;
    return api.getProposalData(options);
  }

  getProposalOverlays(options) {
    return this.apis.fiesta.getProposalOverlays(options);
  }

  getThreeSixtyPreview(options) {
    return this.apis.fiesta.getThreeSixtyPreview(options);
  }

  superUserLogin(user) {
    return this.apis.success.login(user);
  }

  superUserGetAccess(options) {
    return this.apis.success.getLoginToken(options);
  }

  superUserGetOperatorToken(options) {
    return this.apis.success.getOperatorToken(options);
  }

  superUserGetOperator(options) {
    return this.apis.success.getOperator(options);
  }

  superUserUpdateOperator(options) {
    return this.apis.success.updateOperator(options);
  }

  listCountries() {
    return this.apis.salsa.listCountries();
  }

  transformPortfolioSettings(payload, isSalsa) {
    if (isSalsa) {
      return payload;
    }

    return {
      ...payload,
      logoUrl: payload.logoEnabled ? payload.logoUrl : '',
      logoLink: payload.logoEnabled ? payload.logoLink : '',
      backgroundUrl: payload.backgroundEnabled ? payload.backgroundUrl : '',
      title: payload.titleEnabled ? payload.title : '',
      subtitle: payload.titleEnabled ? payload.subtitle : '',
      showCompanyFields: {
        ...payload.showCompanyFields,
        headline: payload.companySection ? payload.showCompanyFields.headline : '',
        email: payload.companySection ? payload.showCompanyFields.email : '',
        phone: payload.companySection ? payload.showCompanyFields.phone : '',
        website: payload.companySection ? payload.showCompanyFields.website : '',
        facebook: payload.companySection ? payload.showCompanyFields.facebook : '',
        twitter: payload.companySection ? payload.showCompanyFields.twitter : '',
        instagram: payload.companySection ? payload.showCompanyFields.instagram : '',
        termsLink: payload.companySection ? payload.showCompanyFields.termsLink : '',
        privacyLink: payload.companySection ? payload.showCompanyFields.privacyLink : '',
      },
    };
  }
}

export default Backend;
